import appData from "../appdata.json";
const LSPath = (appData.STORAGE || "ls") + "-USER";
export const getUser = () => JSON.parse(localStorage.getItem(LSPath) ?? "null");
export const setUser = (user: any) => localStorage.setItem(LSPath, JSON.stringify(user));
export const setAdminUser = (user: any) =>
  localStorage.setItem(LSPath + "-admin", JSON.stringify(user));
export const getAdminUser = () => JSON.parse(localStorage.getItem(`${LSPath}-admin`) ?? "null");
export const setSuperAdminUser = (user: any) =>
  localStorage.setItem(LSPath + "-superAdmin", JSON.stringify(user));
export const getSuperAdminUser = () =>
  JSON.parse(localStorage.getItem(`${LSPath}-superAdmin`) ?? "null");

export const getAppFilterGroupFromStorage = () => localStorage.getItem(`${LSPath}-group`) ?? "";

export const setAppFilterGroupToStorage = (group: any) =>
  localStorage.setItem(`${LSPath}-group`, group);
export const getAppFilterCategoryFromStorage = () =>
  localStorage.getItem(`${LSPath}-category`) ?? "";

export const setAppFilterCategoryToStorage = (category: any) =>
  localStorage.setItem(`${LSPath}-category`, category);

export const getListData = () => {
  try {
    return JSON.parse(localStorage.getItem(`${LSPath}-listData`) ?? "{}");
  } catch (error) {
    return {};
  }
};
export const setListData = (user: string, list: string, paginate: any) => {
  let data = getListData();
  //console.log(data);
  if (!data) {
    data = {};
  }
  if (!data[user]) {
    data[user] = {};
  }
  if (!data[user][list]) {
    data[user][list] = {};
  }
  data[user][list].paginate = paginate;

  localStorage.setItem(`${LSPath}-listData`, JSON.stringify(data));
};
export const getImportSwaggerData = () => {
  try {
    return JSON.parse(localStorage.getItem(`${LSPath}-ImportSwagger`) ?? "{}");
  } catch (error) {
    return {};
  }
};
export const setImportSwaggeData = (user: string, swaggerData: any) => {
  let data = getImportSwaggerData();

  if (!data) {
    data = {};
  }
  data[user] = swaggerData;

  localStorage.setItem(`${LSPath}-ImportSwagger`, JSON.stringify(data));
};
