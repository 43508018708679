export interface IUserCredentials {
  email: string;
  password: string;
}
export interface IUserKeysValues {
  key: string;
  value: string;
  notes?: string;
  group: string;
  id?: string;
}

export const userKeysForValues = ["facility_id"];
export interface IUser {
  name: string;
  id: string;
  _id: string;
  phone: string;
  email: string;
  roles: [];
  token: string;
  userKeysValues: IUserKeysValues[];
  password?: string;
  fromAdmin?: boolean;
  fromSuperAdmin?: boolean;
  settings?: { emailNotification?: boolean; languages?: any[]; autoSendTranslatedData?: boolean };
}

export class UserClass {
  _id?: string;
  name: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  userKeysValues: IUserKeysValues[];
  settings?: { emailNotification?: boolean; languages?: any[]; autoSendTranslatedData?: boolean };
  constructor(initData: any) {
    if (!initData) {
      initData = { name: "", email: "", userKeysValues: [] };
    }
    if (typeof initData._id !== "undefined") {
      this._id = initData._id;
    }
    if (typeof initData.name === "undefined") {
      this.name = "";
    } else {
      this.name = initData.name;
    }
    if (typeof initData.email === "undefined") {
      this.email = "";
    } else {
      this.email = initData.email;
    }
    if (typeof initData.password === "undefined") {
      this.password = "";
    }
    if (typeof initData.confirmPassword === "undefined") {
      this.confirmPassword = "";
    }
    if (typeof initData.userKeysValues === "undefined") {
      this.userKeysValues = [];
    } else {
      this.userKeysValues = initData.userKeysValues;
    }
    if (typeof initData.settings === "undefined") {
      this.settings = { emailNotification: false, languages: [], autoSendTranslatedData: false };
    } else {
      this.settings = initData.settings;
    }
  }
}

export interface IUserKeys {
  key: string;
  value: string;
  id: string;
  _id: string;
}
export class UserKeysClass {
  name: string;
  notes: string;
  id?: string;
  _id?: string;
  constructor(initData: UserKeysClass) {
    if (typeof initData._id !== "undefined") {
      this._id = initData._id;
    }
    if (typeof initData.name === "undefined") {
      this.name = "";
    } else {
      this.name = initData.name;
    }
    if (typeof initData.notes === "undefined") {
      this.notes = "";
    } else {
      this.notes = initData.notes;
    }
  }
}

export interface IAuthState {
  user: IUser | null;
  isFetching: boolean;
  error: boolean;
  force: boolean;
}

export enum AuthActionsList {
  LoginStart = "LOGIN_START",
  ForceLoginStart = "FORCE_LOGIN_START",
  LoginSuccess = "LOGIN_SUCCESS",
  ForceLoginSuccess = "FORCE_LOGIN_SUCCESS",
  LoginFailure = "LOGIN_FAILURE",
  Loguot = "LOGOUT",
  Update = "UPDATE",
}
export type AuthActions = {
  [AuthActionsList.LoginSuccess]: IUser;
  [AuthActionsList.ForceLoginSuccess]: IUser;
  [AuthActionsList.Update]: IUser;
  [AuthActionsList.LoginFailure]: undefined;
  [AuthActionsList.Loguot]: undefined;
  [AuthActionsList.LoginStart]: undefined;
  [AuthActionsList.ForceLoginStart]: undefined;
};
// ***********************************************************************
// ***********************************************************************
// https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm
type AuthActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};
export type AuthContextActions = AuthActionMap<AuthActions>[keyof AuthActionMap<AuthActions>];
// ***********************************************************************
//https://medium.com/@DcKesler/typescript-for-createcontext-and-usereducer-in-react-with-custom-hooks-bc3b19a4b942
export type AuthContextActions2 = {
  [Key in keyof AuthActions]: {
    type: Key;
    payload: AuthActions[Key];
  };
}[keyof AuthActions];
// ***********************************************************************
export type AuthContextActions3 = {
  type:
    | "LOGIN_START"
    | "FORCE_LOGIN_START"
    | "LOGIN_SUCCESS"
    | "FORCE_LOGIN_SUCCESS"
    | "LOGIN_FAILURE"
    | "LOGOUT";
  payload: IUser | null;
};
